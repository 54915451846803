<template>
  <PageWrapper>
    <NavbarMain />
    <h1>Profile</h1>
    <div class="loading__container" v-if="loading">
      <LoadingDotGathering />
    </div>
    <div class="profile__card" v-else>
      <picture class="profile__picture">
        <img :src="userPhoto" alt="user profile photo" />
      </picture>
      <BaseButton action="outline-secondary" @click="handleTriggerClick">
        <p slot="text">Edit photo</p>
      </BaseButton>
      <BaseButton action="tercery" v-if="form.assets_name" @click="savePhoto">
        <p slot="text">
          Save
          <i
            class="button bg-transparent button-saas is-radius is-size is-small"
          >
            <IconCheck class="icon is-small" />
          </i>
        </p>
      </BaseButton>
      <div class="profile__content">
        <div class="prouser__sign__container" v-if="isProUser">
          <ProUserSign />
        </div>
        <h2>{{ user.name }}</h2>
        <p class="profile__email">{{ user.email }}</p>
        <p class="profile__date">Member since {{ createdAt }}</p>
      </div>
      <input
        type="file"
        accept="image/*"
        @change="handleFileUpload($event)"
        id="file"
        class="input file"
        title=" "
        ref="imageSelector"
      />
    </div>
  </PageWrapper>
</template>

<script>
import { mapGetters } from "vuex";
import NavbarMain from "@/components/_shared/NavbarMain.vue";
import PageWrapper from "@/components/_shared/PageWrapper.vue";
import BaseButton from "../components/base-ui/BaseButton.vue";
import LoadingDotGathering from "../components/icons/LoadingDotGathering.vue";
import ProUserSign from "../components/_shared/ProUserSign.vue";

export default {
  name: "Profile",
  components: {
    PageWrapper,
    NavbarMain,
    BaseButton,
    LoadingDotGathering,
    ProUserSign,
  },
  data() {
    return {
      loading: false,
      dateToStringOptions: {
        weekday: "long",
        year: "numeric",
        month: "long",
        day: "numeric",
      },
      form: {
        data: null,
        assets_name: "",
      },
      image: null,
      preview: null,
    };
  },
  computed: {
    ...mapGetters({
      authData: "auth/getAuthData",
    }),
    user() {
      return this.authData.user;
    },
    isProUser() {
      return this.authData.user_type.name === "pro";
    },
    createdAt() {
      return new Date(this.user.created_at).toLocaleDateString(
        undefined,
        this.dateToStringOptions
      );
    },
    userPhoto() {
      if (this.preview) return this.preview;
      if (this.form.assets_name) return this.form.data;
      return (
        this.user.photo ??
        "https://place-hold.it/610x380/DDDDDD/fff.jpg?bold&fontsize=50&text=UserPhoto"
      );
    },
  },
  methods: {
    handleTriggerClick() {
      const elem = this.$refs.imageSelector;
      elem.click();
    },
    handleFileUpload(event) {
      if (event.target.files[0].name) {
        const fileName = event.target.files[0].name.substring(
          0,
          event.target.files[0].name.lastIndexOf(".")
        );
        this.form.assets_name = fileName;
        this.form.data = event.target.files[0];
      }

      const reader = new FileReader();
      this.loading = true;

      reader.onload = function (e) {
        this.preview = e.target.result;
        this.loading = false;
      }.bind(this);

      reader.onerror = function (error) {
        console.info(error);
        this.loading = false;
      };
      this.image = event.target.files[0];
      reader.readAsDataURL(this.image);
    },
    savePhoto() {
      this.loading = true;
      this.$store
        .dispatch("users/updateUserPhoto", this.form)
        .then(() => {
          this.$store.dispatch("auth/getUserData");
          this.resetForm();
        })
        .finally(() => {
          this.loading = false;
        });
    },
    resetForm() {
      this.form = {
        data: null,
        assets_name: "",
      };
      this.preview = null;
    },
  },
};
</script>
<style scoped>
h1 {
  font-weight: 700;
  font-size: 1.5rem;
}
.bg-transparent {
  background-color: transparent;
  color: #fff;
}
.loading__container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 18rem auto 0;
}
.profile__card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  background-color: #fff;
  border-radius: 0.5rem;
  width: clamp(10rem, 50vw, 20rem);
  margin: 8rem auto 0;
}
.profile__picture {
  width: 8rem;
  height: 8rem;
  border-radius: 50%;
  overflow: hidden;
  margin-bottom: 1rem;
}
.profile__picture img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.profile__content {
  margin: 1rem 0;
  text-align: center;
}
.profile__content h2 {
  font-weight: 700;
  font-size: 1.2rem;
}
.profile__email {
  color: #6d6d6d;
}
.profile__date {
  color: #bebebe;
  margin: 0.5rem 0;
}
#file {
  display: none;
}
.prouser__sign__container {
  position: relative;
  margin: 0 auto;
  height: 2rem;
  text-align: center;
  display: flex;
  justify-content: center;
}
</style>
